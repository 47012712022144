<template>
  <div class="form-part">
    <h1 class="form-part__title">
      Verkoop je woning:<br />
      vind jouw verkoopmakelaar!
    </h1>
    <t-form ref="form" @submit="onSubmit" @submit.prevent>
      <div class="form-part__row">
        <PostalField
          class="postal"
          :autofocus="false"
          @result="onResult"
          @change="onChange"
        />
        <ServiceField
          :init-with-value="serviceId"
          hidden
          @result="onResult"
          @change="onChange"
        />
        <FormStepSubmitButton id="submitPostal" text="Vergelijk nu" />
      </div>
    </t-form>
    <UspRow class="mt-4" color="secondary" />
  </div>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import PostalField from 'chimera/all/components/form/fields/postal/PostalField'
import FormStepSubmitButton from 'chimera/all/components/form/steps/FormStepSubmitButton'
import UspRow from 'chimera/all/components/UspRow'
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField'
import { realEstateSalesConsumerNL } from 'chimera/realEstateAgency/service'
import DwellingTypePage from '~/pages/offertes-aanvragen/type-woning'

export default {
  name: 'IndexPostalServiceFormStep',

  components: {
    PostalField,
    ServiceField,
    FormStepSubmitButton,
    UspRow,
  },

  extends: AbstractFormStep,

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceId: realEstateSalesConsumerNL.id,
    }
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(DwellingTypePage)
    },
  },
}
</script>

<style lang="scss">
.form-part {
  &__row {
    display: flex;

    .postal {
      width: 50%;

      .t-text-field__wrapper {
        min-height: 60px;
      }
    }

    .formstep-btn {
      max-width: 50%;
      width: 50%;
      margin-left: 8px;
      min-width: auto;
      margin-top: 0 !important;
    }
  }
}
</style>
