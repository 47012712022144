<template>
  <div>
    <ImageHeader id="header" class="header" scroll-selector="#how-it-works">
      <template #text>
        Vind dé <br />
        makelaar <br />
        in jouw <br />
        regio
      </template>

      <template #card>
        <IndexPostalServiceFormStep :progress-value="0" />
      </template>
    </ImageHeader>

    <HomeBlock id="how-it-works" title="Hoe werkt het?" color="secondary">
      <HowItWorks :steps="howItWorksSteps" />
    </HomeBlock>

    <HomeBlock id="review" title="Wat anderen zeggen">
      <Reviews :reviews="reviews" />
    </HomeBlock>

    <HomeBlock
      id="faq"
      title="Dit zijn de vragen die vaak aan ons gesteld worden"
      horizontal
      color="secondary"
    >
      <FAQ :items="faq" />
    </HomeBlock>

    <HomeBlock id="perks" class="perks">
      <Perks
        title="Bespaar op je makelaarkosten"
        subtitle="Vergelijk makelaars voor je beslist."
        :animation="perksAnimation"
      />
    </HomeBlock>
  </div>
</template>

<script>
import AbstractHomePage from 'chimera/all/components/page/AbstractHomePage'
import HowItWorks from 'chimera/all/components/HowItWorks'
import ImageHeader from 'chimera/all/components/header/ImageHeader'
import Reviews from 'chimera/all/components/review/Reviews'
import HomeBlock from 'chimera/all/components/HomeBlock'
import FAQ from 'chimera/all/components/faq/FAQ'
import Perks from 'chimera/all/components/Perks'
import perksAnimation from '@/assets/animations/perks.json'
import IndexPostalServiceFormStep from '~/components/form/steps/index/IndexPostalServiceFormStep'

export default {
  components: {
    IndexPostalServiceFormStep,
    FAQ,
    ImageHeader,
    HowItWorks,
    Perks,
    HomeBlock,
    Reviews,
  },

  extends: AbstractHomePage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Wat kost een makelaar? Vergelijk makelaars voor je beslist',
      headDescription:
        'Op zoek naar een makelaar? Vind en vergelijk de beste makelaars uit jouw regio.',
      path: '/',
      howItWorksSteps: [
        {
          title: 'Vertel ons wat je zoekt',
          text: 'Geef aan waar je naar op zoek bent en beantwoord een paar vragen.',
          imagePath: require('chimera/all/assets/images/how-it-works/questions.svg'),
        },
        {
          title: 'Wij vinden de beste match',
          text: 'Je aanvraag komt bij ons binnen en wij gaan voor je op zoek naar de makelaars die het beste bij je wensen aansluiten.',
          imagePath: require('chimera/all/assets/images/how-it-works/likeness.svg'),
        },
        {
          title: 'Kom in contact en ontvang offertes',
          text: 'Deze bij ons aangesloten partners nemen contact met je op mochten er nog vragen zijn. Je ontvangt de offertes en kunt prijzen en werkwijzen vergelijken. Zo maak je simpel en snel een weloverwogen keuze.',
          imagePath: require('chimera/all/assets/images/how-it-works/group-record.svg'),
        },
        {
          title: 'Vergelijk en kies',
          text: 'Kies de makelaar die het beste bij jou past. De makelaar neemt het werk voor je uit handen.',
          imagePath: require('chimera/all/assets/images/how-it-works/contract.svg'),
        },
      ],
      reviews: [
        {
          id: 'review_1',
          rating: 4,
          name: 'Stef Zanders',
          text: 'Snelle reactie vanuit de makelaars, diverse offertes ontvangen en kunnen kiezen.',
        },
        {
          id: 'review_2',
          rating: 3.5,
          name: 'Kristie Nuijten',
          text: 'Niet verwacht dat ik gebeld zou worden maar uiteindelijk zo wel een goede makelaar gevonden.',
        },
        {
          id: 'review_3',
          rating: 4.5,
          name: 'Arjen ten Hove',
          text: 'Nette en snelle service, meerdere offertes ontvangen en een fijne makelaar gekozen die meedenkt.',
        },
      ],
      faq: [
        {
          active: false,
          title: 'Waar moet ik op letten bij het vergelijken van makelaars?',
          details: `
           <p>
            Makelaars in Nederland mogen zelf hun prijzen en diensten bepalen. Dit zorgt voor uiteenlopende kosten voor de consument.
          </p>
          <p>Let er bij het vergelijken bijvoorbeeld op:</p>
          <ol>
            <li>
              Welke diensten je belangrijk vindt tijdens de begeleiding van de ver- of aankoop en bekijk goed welke werkzaamheden inbegrepen zijn in de offerte.
            </li>
            <li>
              Of de courtage die de makelaar rekent inclusief of exclusief BTW is.
            </li>
            <li>
              Of er sprake is van een exclusiviteitscontract en/ of intrekkingskosten.
            </li>
          </ol>
          `,
        },
        {
          active: false,
          title: 'Wat gebeurt er met mijn gegevens als ik een aanvraag invul?',
          details:
            'Jouw persoonlijke gegevens zijn bij ons in veilige handen, ze zijn van belang om de juiste makelaars in jouw regio aan je te koppelen. Je gegevens komen alleen bij de geselecteerde makelaars terecht en zullen nooit openbaar gedeeld worden.',
        },
        {
          active: false,
          title: 'Zijn er kosten verbonden aan een aanvraag?',
          details:
            'Nee, een aanvraag indienen is altijd gratis en vrijblijvend!',
        },
      ],
      perksAnimation,
    }
  },
}
</script>

<style lang="scss">
.image-header {
  height: 560px;

  @screen sm {
    height: 700px;
  }

  &__bg {
    height: 400px;
    background-image: url('~../static/images/header/hero-sm.jpg');
    background-position: 50% 0 !important;

    @screen sm {
      height: 500px;
    }

    @screen md {
      background-image: url('~../static/images/header/hero-md.jpg');
      background-position: 50% 85% !important;
    }

    @screen lg {
      background-image: url('~../static/images/header/hero-lg.jpg');
      background-position: 50% 50% !important;
    }

    @screen xl {
      background-image: url('~../static/images/header/hero-xl.jpg');
      background-position: 50% 40% !important;
    }
  }
}
</style>
